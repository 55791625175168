import { styled } from 'styled-components'

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .tips {
    margin-top: 10px;
    color: rgb(22, 119, 255);
  }
`
