import LocalRequest from './request'
import { BASE_URL, TIME_OUT } from './config'

const localRequest = new LocalRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
})

export * from './modules/store/type'
export * from './modules/product/type'
export * from './modules/user/type'
export * from './modules/order/type'
export * from './modules/address/type'

export * from './config'

export default localRequest
