import * as lodash from 'lodash'
import { ProductItem, StoreItem } from '@/service'
import { getStoreById, getStoreList } from '@/service/modules/store'
import { IUserProductItem, RootStateType } from '@/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Toast } from 'antd-mobile'
import { BusinessLocalCache } from '@/utils'

const localCache = new BusinessLocalCache()

export const fetchStoreListAction = createAsyncThunk(
  'store/getStoreList',
  async (_, thunkApi) => {
    const { store: reduxStore } = thunkApi.getState() as RootStateType
    const storeList = await getStoreList()
    if (!storeList) {
      Toast.show({
        icon: 'fail',
        content: '获取商店列表失败',
      })
      return
    }
    thunkApi.dispatch(changeStoreListAction(storeList.data))
  },
)
export const fetchStoreAction = createAsyncThunk(
  'store/getStoreList',
  async (_, thunkApi) => {
    const { store: reduxStore } = thunkApi.getState() as RootStateType
    const storeId = localCache.getStoreId() ?? -1
    const storeInfo = await getStoreById(storeId)
    if (!storeInfo) {
      Toast.show({
        icon: 'fail',
        content: '获取商店信息失败',
      })
      return
    }
    localCache.setStoreId(storeInfo.id)
    thunkApi.dispatch(changeStoreAction(storeInfo))
    const userProductList = lodash
      .cloneDeep(storeInfo.products)
      ?.map((item) => {
        return { ...item, count: 0 }
      })
    thunkApi.dispatch(changeProductListAction(userProductList))
  },
)

interface IStoreState {
  storeList: StoreItem[]
  store: StoreItem | null
  productList: IUserProductItem[]
  cartItemCount: number
  totalPrice: number
}

interface IChangeStoreListAction {
  payload: { product: IUserProductItem; count: number }
}

const initialState: IStoreState = {
  storeList: [],
  store: null,
  productList: [],
  // 购物车商品数量
  cartItemCount: 0,
  totalPrice: 0,
}

const storeSlice = createSlice({
  name: 'store',
  initialState: initialState,
  reducers: {
    changeStoreAction(state, { payload }) {
      // 修改storeList
      state.store = payload
      state.totalPrice = 0
      state.cartItemCount = 0
    },
    changeProductListAction(state, { payload }) {
      state.productList = payload
    },
    changeStoreListAction(state, { payload }) {
      state.storeList = payload
    },
    changeCountAction(state, { payload }: IChangeStoreListAction) {
      const { product, count } = payload
      const newList = [...state.productList]

      const index = newList.findIndex((item) => item.id === product.id)

      newList[index].count = count

      state.productList = newList
      state.totalPrice = newList.reduce(
        (total, item) => total + item.count! * Number(item.price)!,
        0,
      )

      state.cartItemCount = state.productList.reduce(
        (total, item) => total + item.count!,
        0,
      )
    },
  },
})

export const {
  changeStoreAction,
  changeCountAction,
  changeProductListAction,
  changeStoreListAction,
} = storeSlice.actions
export default storeSlice.reducer
