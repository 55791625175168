import React from 'react'
import { Navigate, type RouteObject } from 'react-router-dom'

// 一级路由
const Main = React.lazy(() => import('@/views/main'))
const Address = React.lazy(() => import('@/views/address'))
const AddressAdd = React.lazy(() => import('@/views/address-add'))
const Store = React.lazy(() => import('@/views/store'))
const OrderDetail = React.lazy(() => import('@/views/order-detail'))
const Entrance = React.lazy(() => import('@/views/entrance'))
const Report = React.lazy(() => import('@/views/report'))

// 二级路由
const Home = React.lazy(() => import('@/views/home'))
const Product = React.lazy(() => import('@/views/product'))
const Order = React.lazy(() => import('@/views/order'))
const Me = React.lazy(() => import('@/views/me'))

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/main" />,
  },
  {
    path: '/main',
    element: <Main />,
    children: [
      {
        path: '/main',
        element: <Navigate to="/main/home" />,
      },
      {
        path: '/main/home',
        element: <Home />,
      },
      {
        path: '/main/product',
        element: <Product />,
      },

      {
        path: '/main/order',
        element: <Order />,
      },
      {
        path: '/main/me',
        element: <Me />,
      },
    ],
  },
  {
    path: '/address',
    element: <Address />,
  },
  {
    path: '/address-add',
    element: <AddressAdd />,
  },
  {
    path: '/store',
    element: <Store />,
  },
  {
    path: '/order-detail',
    element: <OrderDetail />,
  },
  {
    path: '/signin',
    element: <Entrance />,
  },
  {
    path: '/report',
    element: <Report />,
  },
]

export default routes
