import localRequest from '@/service'

export async function getWxConfigInfo(url: string) {
  return await localRequest.post<any>('/wx/page_sign', { url })
}

export async function getWeChatLoginURL(redirect: string, state: string) {
  return await localRequest.get<string>('/wx/wx_login', {
    params: { redirect, state },
  })
}

export async function getOpenIdByCode(code: string) {
  return await localRequest.get<string>('/wx/openid', { params: { code } })
}
