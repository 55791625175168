import localRequest from '@/service'
import { IGetOrderList, IWeChatPrepayInfo, OrderItem } from './type'
import { PayMethodEnum } from '@/type'

/** 返回订单id */
export async function createOrder(
  storeId: number,
  products: { id: number; count: number }[],
) {
  return localRequest.post<number>('/order/create', {
    storeId,
    products,
  })
}

export async function createWeChatPrepayOrder(openId: string, orderId: number) {
  return localRequest.post<IWeChatPrepayInfo>('/order/pay_config', {
    openId,
    orderId,
    method: PayMethodEnum.Wechat,
  })
}

export async function createAliPayPrepayOrder(orderId: number) {
  return localRequest.post<string>('/order/pay_config', {
    orderId,
    method: PayMethodEnum.Alipay,
  })
}

export async function getOrderList(page = 1, limit = 99) {
  return localRequest.get<IGetOrderList>('/order/user', {
    params: { page, limit },
  })
}

export async function getOrderById(id: number) {
  return localRequest.get<OrderItem>('/order/get/' + id)
}

export async function updateOrderInfo(
  order: Omit<Partial<OrderItem>, 'id'> & { id: number },
) {
  return localRequest.patch<OrderItem>('/order/update', order)
}

// export async function deleteOrder(id: number) {
//   return await localRequest.delete<boolean>('/order/delete/' + id)
// }
