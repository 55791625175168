import React, { FC, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'

import './App.less'
import routes from './router'
import Loading from './components/loading'
import { useWxConfig } from './hooks/useWxConfig'

const App: FC = () => {
  useWxConfig()

  return (
    <div className="app">
      <Suspense fallback={<Loading />}>{useRoutes(routes)}</Suspense>
    </div>
  )
}

export default App
