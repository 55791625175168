import localRequest from '@/service'
import { IGetUserInfo, UserItem } from './type'

export async function getUserInfoById(id: number) {
  const res = localRequest.get<IGetUserInfo>('/user/get/' + id)

  return await localRequest.get<IGetUserInfo>('/user/get/' + id)
}

export async function updateUserInfo(user: UserItem) {
  return localRequest.patch('/user/update', user)
}

// 登录
export async function signIn(username: string, password: string) {
  return await localRequest.post('/auth/signin', { username, password })
}

// 注册
export async function signUp(username: string, password: string) {
  return await localRequest.post('/user/signup', { username, password })
}
