import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'normalize.css'

import './assets/css/index.less'
import { Provider } from 'react-redux'
import store from '@/store'

import App from './App'
import { SafeArea } from 'antd-mobile'

// cache.clear()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
      <SafeArea position="bottom" />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>
)
