import React, { memo } from 'react'
import type { FC, ReactNode } from 'react'
import { DotLoading } from 'antd-mobile'
import { LoadingWrapper } from './style'

interface ILoadingProps {
  children?: ReactNode
}
const Loading: FC<ILoadingProps> = () => {
  return (
    <LoadingWrapper>
      <DotLoading color="primary" style={{ fontSize: 18 }} />
      <div className="tips">Loading...</div>
    </LoadingWrapper>
  )
}

export default memo(Loading)
