import localRequest, { IGetStoreList, StoreItem } from '@/service'

export async function getStoreList() {
  return await localRequest.get<IGetStoreList | undefined>('/store/list', {
    params: { page: 1, limit: 99 },
  })
}

export async function getStoreById(id: string) {
  return localRequest.get<StoreItem | undefined>('/store/get/' + id)
}
