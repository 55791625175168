import { Cache, CacheKeyEnum } from '@/utils'
import { Toast } from 'antd-mobile'
import type {
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from 'axios'
import axios from 'axios'

interface IResponse<T> {
  error: boolean
  statusCode: number
  message: string
  data?: T
  path?: string
  method?: string
}

class LocalRequest {
  instance: AxiosInstance
  cache: Cache

  constructor(config: CreateAxiosDefaults<any>) {
    this.instance = axios.create(config)
    this.cache = new Cache('localStorage')
    // 全局的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        const token = this.cache.get(CacheKeyEnum.TOKEN)
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        return config
      },
      (err) => {
        return err
      },
    )

    this.instance.interceptors.response.use(
      (res) => {
        return res.data.data
      },
      async (err) => {
        if (err.response?.data.statusCode === 401) {
          window.location.href = '/signin'
        } else {
          const errMsg = err.response?.data?.message
          if (errMsg) {
            Toast.show({
              icon: 'fail',
              content: errMsg,
            })
          }
        }
        return err.response?.data
      },
    )
  }

  request<T = any>(config: AxiosRequestConfig) {
    return this.instance.request<any, IResponse<T>>(config)
  }

  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.instance.get<any, T>(url, config)
  }

  post<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.instance.post<any, T>(url, data, config)
  }

  put<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.instance.put<any, T>(url, config)
  }

  delete<T = any>(url: string, config?: AxiosRequestConfig) {
    return this.instance.delete<any, T>(url, config)
  }

  patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig) {
    return this.instance.patch<any, T>(url, data, config)
  }
}

export default LocalRequest
