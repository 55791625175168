import { ProductItem } from '@/service'
import { getProductList } from '@/service/modules/product'
import { RootStateType } from '@/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getProductListAction = createAsyncThunk(
  'store/getProductList',
  async (_, thunkApi) => {
    const state: RootStateType = thunkApi.getState() as any
    const { data: productList, total } = await getProductList(
      state.product.page,
      state.product.pageSize,
    )

    thunkApi.dispatch(changeProductListAction(productList))
    thunkApi.dispatch(changeTotalAction(total))
  },
)

interface IProductState {
  productList: ProductItem[]
  page: number
  pageSize: number
  total: number
}

const initialState: IProductState = {
  productList: [],
  page: 1,
  pageSize: 5,
  total: 100,
}

const productSlice = createSlice({
  name: 'product',
  initialState: initialState,
  reducers: {
    changeProductListAction(state, { payload }) {
      state.productList = payload
    },
    changeTotalAction(state, { payload }) {
      state.total = payload
    },
    changePageInfoAction(state, { payload }) {
      state.pageSize = payload.pageSize
      state.page = payload.page
    },
  },
})

export const {
  changeProductListAction,
  changeTotalAction,
  changePageInfoAction,
} = productSlice.actions
export default productSlice.reducer
