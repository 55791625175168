import {
  getOpenIdByCode,
  getWeChatLoginURL,
  getWxConfigInfo,
} from '@/service/modules/wx'
import { Cache, CacheKeyEnum } from '@/utils/cache'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserAgent } from '@/utils'
import { Toast } from 'antd-mobile'

const sessionCache = new Cache('sessionStorage')
const localCache = new Cache('localStorage')

export function useWxConfig() {
  const location = useLocation()
  const navigate = useNavigate()
  const [openId, setOpenId] = useState<string | null>(null)
  const [appId, setAppId] = useState<string | null>(null)

  const registerWeXinJsApi = useCallback(async () => {
    const url = window.location.href
    const storedKey = window.location.pathname + window.location.search
    let wxConfig = sessionCache.get(`config_${storedKey}`)
    if (!wxConfig) {
      // TODO: 做请求的类型规范
      wxConfig = await getWxConfigInfo(url)
      // console.log({ wxConfig, url })
      // alert(JSON.stringify({ wxConfig, url }, null, 2))
      if (wxConfig.error) return
      // 将配置缓存起来
      sessionCache.set(`config_${storedKey}`, wxConfig)
    }

    // 由于getWxConfigInfo 是异步的，执行到这里以后页面的url可能已经发生了改变，所以此处停止执行
    if (window.location.href !== url) return

    if (wxConfig.appId) setAppId(wxConfig.appId)

    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      ...wxConfig,
      jsApiList: [
        'updateAppMessageShareData',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
      ], // 必填，需要使用的JS接口列表
    })

    window.wx.ready(() => {
      const homeURL = `${window.location.protocol}//${window.location.hostname}`
      const imgUrl = `${window.location.protocol}//${window.location.hostname}/image/coffee_img.png`
      // 为分享给朋友页面设置统一分享样式
      window.wx.onMenuShareAppMessage({
        title: '欢迎光临左岸Coffee', // 分享标题
        desc: '【左岸咖啡】快捷下单、一键配送，满足您的任何需要', // 分享描述
        link: homeURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        type: '', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      })
      // 为分享给朋友圈
      window.wx.onMenuShareTimeline({
        title: '欢迎光临左岸Coffee', // 分享标题
        desc: '【左岸咖啡】快捷下单、一键配送，满足您的任何需要', // 分享描述
        link: homeURL, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: imgUrl, // 分享图标
        type: '', // 分享类型,music、video或link，不填默认为link
        dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
      })
    })

    window.wx.error(async () => {
      Toast.show({
        icon: 'fail',
        content: '微信配置失败，请刷新重试',
      })
    })
  }, [])

  // 为每个页面注册信息(微信文档要求)
  useEffect(() => {
    if (getUserAgent() !== 'WeXin') return
    registerWeXinJsApi().then(() => {})
  }, [location.pathname, location.search, registerWeXinJsApi])

  // 获取openid
  useEffect(() => {
    if (getUserAgent() !== 'WeXin') return
    // 判断是否已经获取了code
    const search = Object.fromEntries(
      new URLSearchParams(location.search).entries(),
    )
    if (search.code) {
      // 通过code获取openid
      getOpenIdByCode(search.code).then((res) => {
        // 将openid存储到缓存
        localCache.set(CacheKeyEnum.OPEN_ID, res)
        setOpenId(res)
        // 还原search
        if (search.state && Object.keys(search.state).length !== 0) {
          const stateStr = new URLSearchParams(search.state).toString()
          navigate('?' + stateStr)
        }
      })
    } else {
      // 如果本地已经存在则不需要再获取
      const openid = localCache.get(CacheKeyEnum.OPEN_ID)
      setOpenId(openid)
      if (openid) return
      // 获取openid
      const redirect = encodeURIComponent(window.location.href.split('?')[0])
      const state = encodeURIComponent(window.location.search)
      getWeChatLoginURL(redirect, state).then((loginUrl) => {
        if (!loginUrl) {
          Toast.show({
            icon: 'fail',
            content: '获取微信登录地址失败',
          })
          navigate(-1)
        }
        window.location.href = loginUrl
      })
    }
  }, [location.search, navigate])

  return { openId, appId }
}
