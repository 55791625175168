import localRequest from '@/service'
import { IGetProductList, ProductItem } from './type'

export async function addProduct(storeId: number, product: ProductItem) {
  return localRequest.post('/product/add', { ...product, storeId })
}

export async function getProductList(page = 1, limit = 20) {
  return localRequest.get<IGetProductList>('/product/list', {
    params: { page, limit },
  })
}

export async function updateProductInfo(product: ProductItem) {
  return localRequest.patch<ProductItem>('/product/update', product)
}

export async function deleteProduct(id: number) {
  return await localRequest.delete<boolean>('/product/delete/' + id)
}
