import { configureStore } from '@reduxjs/toolkit'
import {
  useSelector,
  useDispatch,
  shallowEqual,
  TypedUseSelectorHook,
} from 'react-redux'

import storeReducer from './modules/store'
import productReducer from './modules/product'
import userReducer from './modules/user'
import orderReducer from './modules/order'
import { ProductItem } from '@/service'

const store = configureStore({
  reducer: {
    store: storeReducer,
    product: productReducer,
    user: userReducer,
    order: orderReducer,
  },
})

export interface IUserProductItem extends ProductItem {
  count?: number
}

export type GetStateFnType = typeof store.getState
export type RootStateType = ReturnType<GetStateFnType>
type DispatchType = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootStateType> = useSelector
export const useAppDispatch: () => DispatchType = useDispatch
export const shallowEqualApp = shallowEqual

export default store
