import { ProductItem } from '../product/type'
import { StoreItem } from '../store/type'
import { UserItem } from '../user/type'

export enum OrderStatusEnum {
  UNPAID = 1,
  PAID = 2,
  DELIVERED = 3,
  FINISHED = 4,
  CANCELED = 5,
}

export interface OrderProduct {
  id: number
  order: OrderItem
  product: ProductItem
  quantity: number
}

export interface OrderItem {
  id: number
  orderTime: Date
  orderStatus: OrderStatusEnum
  /** 为了确保用户下单以后的订单地址，不会因为用户修改地址而改变，所以此处暂时使用string（json格式），拷贝一份 */
  address: string
  user: UserItem
  store?: StoreItem
  orderProducts?: OrderProduct[]
  deliveryPrice: number
  remark: string
  wxTansactionId: string
}

export interface IGetOrderList {
  data: OrderItem[]
  total: number
}

export interface IWeChatPrepayInfo {
  // 调起微信支付的参数
  appId: string
  timeStamp: string
  nonceStr: string
  package: string
  signType: string
  paySign: string
}
