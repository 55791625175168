import { AddressItem, OrderItem, OrderStatusEnum } from '@/service'
import { getOrderList } from '@/service/modules/order'
import { RootStateType } from '@/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

interface IOrderState {
  // 订单页面订单地址列表
  orderAddr?: AddressItem
}

const initialState: IOrderState = {
  orderAddr: undefined,
}

const orderSlice = createSlice({
  name: 'order',
  initialState: initialState,
  reducers: {
    changeOrderAddrAction: (state, action) => {
      state.orderAddr = action.payload
    },
  },
})

export const { changeOrderAddrAction } = orderSlice.actions
export default orderSlice.reducer
