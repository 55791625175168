export enum CacheKeyEnum {
  ADDR = 'addr',
  ORDER_ITEM = 'order_item',
  USER_ID = 'user_id',
  TOKEN = 'token',
  OPEN_ID = 'open_id',
  Store_ID = 'store_id',
}

export class Cache {
  private cacheInstance: Storage

  constructor(type: 'localStorage' | 'sessionStorage') {
    this.cacheInstance = window[type]
  }

  get<T = any>(key: string): T | undefined {
    const value = this.cacheInstance.getItem(key)
    if (
      value === undefined ||
      value === null ||
      value === 'undefined' ||
      value === 'null'
    ) {
      return undefined
    }
    return JSON.parse(value)
  }

  remove(key: string) {
    this.cacheInstance.removeItem(key)
  }

  clear() {
    this.cacheInstance.clear()
  }

  set(key: string, value: any) {
    this.cacheInstance.setItem(key, JSON.stringify(value))
  }
}

export class BusinessLocalCache extends Cache {
  constructor() {
    super('localStorage')
  }

  getStoreId() {
    return this.get(CacheKeyEnum.Store_ID)
  }

  setStoreId(storeId: number) {
    return this.set(CacheKeyEnum.Store_ID, storeId)
  }

  getUserId() {
    return this.get(CacheKeyEnum.USER_ID)
  }

  setUserId(userId: number) {
    return this.set(CacheKeyEnum.USER_ID, userId)
  }
}
