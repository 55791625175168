import localRequest from '@/service'
import { AddressItem, IGetAddrList } from './type'

export async function getAddrList(id: number, page: number, limit: number) {
  return localRequest.get<IGetAddrList>('/address/user', {
    params: { id, page, limit },
  })
}

export async function deleteAddr(id: number) {
  return localRequest.delete<boolean>('/address/delete/' + id)
}

export async function updateAddr(data: AddressItem & { id: number }) {
  return localRequest.patch<AddressItem>('/address/update', data)
}

export async function addAddr(data: AddressItem) {
  return localRequest.post<AddressItem>('/address/add', data)
}
