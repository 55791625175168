import { AddressItem, OrderItem, UserItem } from '@/service'
import { deleteAddr, getAddrList, updateAddr } from '@/service/modules/address'
import { getOrderList } from '@/service/modules/order'
import { getUserInfoById } from '@/service/modules/user'
import { RootStateType } from '@/store'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

export const getUserInfoAction = createAsyncThunk(
  'store/getUserList',
  async ({ userId }: { userId: number }, thunkApi) => {
    const userInfo = await getUserInfoById(userId)
    thunkApi.dispatch(changeUserAction(userInfo))
  },
)

export const getAddrListAction = createAsyncThunk(
  'store/getAddrList',
  async ({ userId }: { userId: number }, thunkApi) => {
    const addrRes = await getAddrList(userId, 1, 99)
    thunkApi.dispatch(changeAddrListAction(addrRes.data))
  },
)

export const deleteAddrAction = createAsyncThunk(
  'store/deleteAddr',
  async ({ addrId }: { addrId: number }, thunkApi) => {
    const { user: userReduce } = thunkApi.getState() as RootStateType
    const isSuccess = await deleteAddr(addrId)
    if (isSuccess) {
      thunkApi.dispatch(getAddrListAction({ userId: userReduce.userInfo.id }))
    }
  },
)

export const updateAddrAction = createAsyncThunk(
  'store/updateAddr',
  async (addrItem: Partial<AddressItem> & { id: number }, thunkApi) => {
    const { user: userReduce } = thunkApi.getState() as RootStateType
    const newAddrItem = await updateAddr(addrItem)
    if (newAddrItem) {
      thunkApi.dispatch(getAddrListAction({ userId: userReduce.userInfo.id }))
    }
  },
)

export const fetchOrderListAction = createAsyncThunk(
  'store/fetchOrderList',
  async (_, thunkApi) => {
    const orderListRes = await getOrderList()
    if (orderListRes) {
      thunkApi.dispatch(changeOrderListAction(orderListRes.data))
    }
  },
)

interface IUserState {
  userInfo: UserItem
  addressList: AddressItem[]
  orderList: OrderItem[]
}

const initialState: IUserState = {
  userInfo: {} as UserItem,
  addressList: [],
  orderList: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    changeUserAction(state, { payload }) {
      state.userInfo = payload
    },
    changeAddrListAction(state, { payload }) {
      state.addressList = payload
    },
    changeOrderListAction(state, { payload }) {
      state.orderList = payload
    },
  },
})

export const { changeUserAction, changeAddrListAction, changeOrderListAction } =
  userSlice.actions
export default userSlice.reducer
