/**
 * 随机生成尽可能不重复的字符串
 * @param width 字符串位数
 * @returns
 */
export function generateUniqueString(width: number, charset?: string): string {
  const charSet = charset
    ? charset
    : 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  let result = ''
  for (let i = 0; i < width; i++) {
    const randomIndex = Math.floor(Math.random() * charSet.length)
    result += charSet.charAt(randomIndex)
  }
  return result
}

export type UserAgent =
  | 'AliPay'
  | 'WeXin'
  | 'Chrome'
  | 'Firefox'
  | 'Safari'
  | 'Unknown'

export function getUserAgent(): UserAgent {
  const ua = navigator.userAgent

  if (/AlipayClient/i.test(ua)) {
    return 'AliPay'
  } else if (/MicroMessenger/i.test(ua)) {
    return 'WeXin'
  } else if (/Chrome/i.test(ua) && !/Edge|Edg|OPR/i.test(ua)) {
    return 'Chrome'
  } else if (/Firefox/i.test(ua)) {
    return 'Firefox'
  } else if (/Safari/i.test(ua) && !/Chrome|CriOS/i.test(ua)) {
    return 'Safari'
  } else {
    return 'Unknown'
  }
}
